import React from "react"
import PropTypes from "prop-types"
import { ContentWrapperSection } from "./index.style"

interface Props {
  children: React.ReactNode
  noMarginTop?: boolean
}

const ContentWrapper: React.FC<Props> = ({ children, noMarginTop }) => {
  return (
    <ContentWrapperSection noMarginTop={noMarginTop}>
      {children}
    </ContentWrapperSection>
  )
}

export { ContentWrapper }

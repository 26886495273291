import styled from "styled-components"
import { Style } from "../../style"

interface ContentWrapperSectionProps {
  noMarginTop: boolean
}

const ContentWrapperSection = styled.section<ContentWrapperSectionProps>`
  margin-left: auto;
  margin-right: auto;
  max-width: ${Style.layout.maxWidth.main}px;

  ${props =>
    props.noMarginTop
      ? `margin-top: 0`
      : `
  margin-top: ${Style.megaClamp(5, 8)};
  `}

  p {
    max-width: ${Style.layout.maxWidth.contentWrapper}px;
    margin-left: auto;
    margin-right: auto;
    color: ${Style.color.foreground};
    font-weight: ${Style.font.weight.normal};
    font-size: ${Style.megaClamp(2, 2.5)};
    line-height: ${Style.megaClamp(3, 4)};
    margin-bottom: ${Style.megaClamp(
      2,
      4,
      Style.layout.maxWidth.contentWrapper
    )};
  }

  a {
    color: ${Style.color.brand};
    text-decoration: none;
    position: relative;

    ${Style.focusAccessible()};
    ${Style.linkHoverAnimation()};
  }

  img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`

export { ContentWrapperSection }
